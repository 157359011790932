import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';

function getRandomIntInRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function Bandits() {
    const theme = useTheme();
    const [randomNumber, setRandomNumber] = useState(null);
    const [totalCount, setTotalCount] = useState(0);

    const handleButtonClick = (min, max) => {
        const newRandomNumber = getRandomIntInRange(min, max);
        setRandomNumber(newRandomNumber);
        setTotalCount(prevTotal => prevTotal + newRandomNumber); // Update total count
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 4 }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 100,
                    height: 100,
                    bgcolor: theme.palette.background.paper,
                    border: `5px solid ${theme.palette.divider}`,
                    borderRadius: 2,
                    mb: 4,
                }}
            >
                <Typography variant="h4" component="div">
                    {randomNumber !== null ? randomNumber : ''}
                </Typography>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                }}
            >
                <Button
                    variant="contained"
                    color="error"
                    sx={{ width: 160, height: 160, margin: 2 }}
                    onClick={() => handleButtonClick(-2, 8)}
                >
                </Button>
                <Button
                    variant="contained"
                    sx={{
                        width: 160,
                        height: 160,
                        margin: 2,
                        backgroundColor: '#007BFF',
                        color: '#FFF',
                        '&:hover': {
                            backgroundColor: '#0056b3',
                        },
                    }}
                    onClick={() => handleButtonClick(3, 5)}
                >
                </Button>
                <Button
                    variant="contained"
                    color="success"
                    sx={{ width: 160, height: 160, margin: 2 }}
                    onClick={() => handleButtonClick(2, 3)}
                >
                </Button>
            </Box>

            <Typography variant="h6" sx={{ mt: 4 }}>
                Total:
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 200,
                    height: 100,
                    bgcolor: theme.palette.background.paper,
                    border: `5px solid ${theme.palette.divider}`,
                    borderRadius: 2,
                    mt: 0,
                }}
            >
                <Typography variant="h4" component="div">
                    {totalCount}
                </Typography>
            </Box>
        </Box>
    );
}

export default Bandits;
